.wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  box-shadow: var(--shadow);
  padding: 20px 40px;
  border-radius: var(--radius-s);
}
.header h3 {
  margin: 0 16px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 40px;
}
.back-btn {
  transform: rotate(90deg);
  width: 40px;
  padding: 0;
}
.submit-btn {
  margin-left: auto;
  width: 222px;
}
.body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 40px;
}
.fields-row {
  display: flex;
  gap: 20px;
}
.field {
  flex: 2;
}
.models-wrapper {
  background-color: var(--light-grey);
  border-radius: var(--radius-s);
  padding: 20px;
  padding-right: 0;
  min-height: 349px;
}
.models-list {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  overflow-y: scroll;
  margin-top: 8px;
}
.models-row {
  flex: 0 1 calc(50% - 40px);
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey);
  padding: 10px 0;
}
.models-row:not(:nth-child(2n)) {
  margin-right: 40px;
}
.vehicle-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
