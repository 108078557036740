.map {
  grid-area: map;
  flex: 1;
  min-width: 400px;
  overflow: hidden;
}
.episodes {
  grid-area: episodes;
  position: relative;
  font-size: 12px;
}
.episodes-filter {
  max-width: 170px;
  margin-bottom: 24px;
}
.episodes-list {
  overflow-y: scroll;
  height: calc(100vh - 562px);
}
.episode {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--table-line);
  margin-bottom: 10px;
  padding: 10px 20px;
}
.episode-row-address,
.episode-row {
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}
.episode-row-address {
  justify-content: flex-start;
}
.episode-row:last-child {
  margin: 0;
}
.episode-address {
  display: block;
  width: 475px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.episode-point {
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--accent);
  margin: 4px 12px 0 0;
}
.episode-hr {
  position: absolute;
  left: 2.5px;
  bottom: 4px;
  height: 32px;
  width: 2px;
  background-color: var(--accent);
}
.map-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--white);
  border: 2px solid var(--accent);
  color: var(--accent);
  transition: all 200ms ease;
}
