.vehicle-marker {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: var(--accent);
  border: 1px solid var(--accent);
  transition: all 200ms ease;
}
.vehicle-marker path:nth-child(2) {
  fill: var(--white);
}