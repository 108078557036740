.map {
  grid-area: map;
  flex: 1;
  min-width: 400px;
  overflow: hidden;
}

.main-body,
.extra-body,
.doors-body,
.light-body,
.dpf-body,
.temperature-body,
.pedals-body {
  display: grid;
  grid-template-columns: repeat(3, 180px);
  grid-gap: 10px;
}
.main-body {
  grid-template-areas:
    'odometer engineSpeed speed'
    'odometer engineSpeed speed'
    'fuelLevel engineLoad indicatorMIL'
    'fuelDTE ignition key';
  grid-template-rows: 80px 80px 76px 76px;
}
.extra-body {
  grid-template-areas:
    'totalFuelUsed fuelRate fuelAFE'
    'batteryVoltage scrReagentLevel lastActivity'
    'status empty empty';
  grid-template-rows: 80px 76px 76px;
}
.temperature-body {
  grid-template-areas: 'ambientAirTemperature engineCoolantTemperature scrReagentTemperature';
  grid-template-rows: 76px;
}
.pedals-body {
  grid-template-areas: 'clutchPedalPosition brakePedalPosition accelPedalPosition';
  grid-template-rows: 76px;
}
.light-body {
  grid-template-areas: 'mainBeamStatus positionLightsStatus highBeamStatus';
  grid-template-rows: 76px;
}
.doors-body {
  grid-template-areas: 'driverDoor passDoor empty';
  grid-template-rows: 76px;
}
.dpf-body {
  grid-template-areas:
    'dpfCommandLamp dpfCommandLamp dpfTemperatureLamp'
    'dpfRegenInhibitStatus dpfPressure sootLoad';
  grid-template-rows: 76px 76px;
}

.dpfstatus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  height: 60px;
  border-radius: var(--radius-s);
}
.dpfstatus-value {
  font-size: var(--font-size-l);
  font-family: var(--font-bold);
  font-weight: var(--font-weight-m);
}
.dpfstatus-date {
  font-size: var(--font-size-ms);
}
.dpfstatus-1 {
  color: var(--light);
  /* TODO: Добавить новый цвет в библиотеку */
  background-color: #da9f06;
}
.dpfstatus-2 {
  color: var(--light);
  /* TODO: Заменить на переменную из библиотеки */
  background-color: #b3261e;
}
div:has(> .dpfstatus-empty) {
  grid-template-rows: 0 128px 380px 104px 112px 32px 60px;
}

.widget {
  display: flex;
  position: relative;
}
.single {
  align-items: center;
  padding: 0 20px 0 12px;
  font-size: 13px;
}
.double {
  flex-direction: column;
  padding: 0 26px 0 12px;
}
.block-header {
  height: 40px;
  font-weight: var(--font-weight-l);
  font-family: var(--font-bold);
  font-size: var(--font-size-m);
}
.indicator {
  position: absolute;
  top: 9px;
  right: 7px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.double-head {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-top: 22px;
  border-bottom: 1px solid var(--dark-grey);
  font-weight: var(--font-weight-l);
  font-family: var(--font-bold);
  font-size: var(--font-size-m);
  line-height: 18px;
}
.odometer .double-head {
  flex-direction: row;
  justify-content: space-between;
}
.double-foot {
  flex: 1;
  display: flex;
  align-items: flex-start;
  font-size: 13px;
  padding-top: 8px;
}

/* FIELDS GRID AREA */
.dpfstatus {
  grid-area: dpfstatus;
}
.main {
  grid-area: main;
}
.light {
  grid-area: light;
}
.extra {
  grid-area: extra;
}
.doors {
  grid-area: doors;
}
.dpf {
  grid-area: dpf;
}
.temperature {
  grid-area: temperature;
}
.pedals {
  grid-area: pedals;
}
.empty {
  grid-area: empty;
}
.lastActivity {
  grid-area: lastActivity;
}
.odometer {
  grid-area: odometer;
}
.fuelLevel {
  grid-area: fuelLevel;
}
.fuelDTE {
  grid-area: fuelLevel;
}
.fuelIFE {
  grid-area: fuelIFE;
}
.totalFuelUsed {
  grid-area: totalFuelUsed;
}
.fuelAFE {
  grid-area: fuelAFE;
}
.fuelRate {
  grid-area: fuelRate;
}
.status {
  grid-area: status;
}
.ignition {
  grid-area: ignition;
}
.speed {
  grid-area: speed;
}
.engineSpeed {
  grid-area: engineSpeed;
}
.indicatorMIL {
  grid-area: indicatorMIL;
}
.accelPedalPosition {
  grid-area: accelPedalPosition;
}
.brakePedalPosition {
  grid-area: brakePedalPosition;
}
.clutchPedalPosition {
  grid-area: clutchPedalPosition;
}
.engineLoad {
  grid-area: engineLoad;
}
.key {
  grid-area: key;
}
.fuelRate {
  grid-area: fuelRate;
}
.ambientAirTemperature {
  grid-area: ambientAirTemperature;
}
.dpfCommandLamp {
  grid-area: dpfCommandLamp;
}
.dpfTemperatureLamp {
  grid-area: dpfTemperatureLamp;
}
.dpfRegenInhibitStatus {
  grid-area: dpfRegenInhibitStatus;
}
.dpfPressure {
  grid-area: dpfPressure;
}
.sootLoad {
  grid-area: sootLoad;
}
.scrReagentLevel {
  grid-area: scrReagentLevel;
}
.scrReagentTemperature {
  grid-area: scrReagentTemperature;
}
