.table {
  padding: 0 40px;
}

.filters-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 40px 20px 40px;
  margin-bottom: 10px;
  max-height: 104px;
}
.filters-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
}

.create-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 25px 40px 40px 40px;
}
.create-wrapper header {
  line-height: 60px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin-bottom: 28px;
}

.create-close {
  position: absolute !important;
  top: 27px;
  right: 16px;
  width: 24px !important;
  height: 24px !important;
}
.create-field {
  margin-bottom: 10px;
}
