.header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 165px;
  overflow: visible;
  margin-bottom: 30px;
}
.header header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 60px;
}
.input-wrapper {
  display: flex;
  align-items: flex-end;
  gap: 20px;
}

.apply {
  width: 222px;
}
