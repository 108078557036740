.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 20px;
}
.title {
  margin-top: 20px;
  font-size: var(--font-size-l);
  text-align: center;
}
.wrapper header > span {
  font-family: var(--font-regular);
  font-weight: var(--font-weight-s);
}
.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 72px;
  width: 320px;
  border-radius: var(--radius-l);
  background: var(--white);
  box-shadow: 0px 8px 20px 0px rgba(151, 155, 166, 0.4);
  padding: 40px;
}
.form header {
  font-size: var(--font-size-l);
  font-family: var(--font-bold);
  font-weight: var(--font-weight-m);
  margin-bottom: 30px;
}
.form span {
  text-align: center;
  font-size: 13px;
  margin-bottom: 30px;
}
.field {
  width: 320px;
  margin-bottom: 30px;
}
.field input {
  height: 60px;
  border-radius: var(--radius-m);
}
.submit {
  width: 320px;
  margin-bottom: 10px;
  height: 60px !important;
  border-radius: var(--radius-m);
}
.submit:nth-of-type(2) {
  margin-bottom: 0;
}
