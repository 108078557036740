.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  padding: 0 24px 30px 32px;
  text-align: center;
  border-top: 5px solid var(--accent);
}
.wrapper header {
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  margin: 30px 0 18px 0;
  align-items: center;
}
.confirm {
  margin-bottom: 32px;
}
.wrapper footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px
}
.wrapper footer button {
  width: 160px;
}
