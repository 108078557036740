.wrapper {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
}
.wrapper header {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: var(--light-black);
  font-size: 16px;
  font-family: var(--font-regular);
  font-weight: var(--font-weight-s);
}
.wrapper header b {
  font-family: var(--font-bold);
}
.profile {
  display: flex;
  align-items: center;
  gap: 8px;
}
.profile-link {
  font-family: var(--font-regular);
  font-weight: 400;
  font-size: var(--font-weight-s);
  margin-right: 8px;
}
