.mileage-body h2 {
  margin: 0;
  font-size: 20px;
  font-weight: var(--font-weight-l);
  font-family: var(--font-bold);
  margin-bottom: 10px;
}
.time-wrapper + hr {
  max-height: 1px;
  width: 100%;
  margin-bottom: 15px;
}
.mileage-body .range {
  gap: 10px;
}
.time-wrapper {
  display: flex;
  margin-top: 20px;
  gap: 20px;
}
.time-wrapper span:first-child {
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-s);
}
.range {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 2px;
}
.mileage-wrapper {
  display: flex;
  font-weight: var(--font-weight-m);
  
  font-size: var(--font-size-m);
  gap: 20px;
  margin-bottom: 40px;
}
.progress-bar {
  background: var(--light-grey);
}
.plate-row {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
