.wrapper {
  grid-area: list;
}
.empty {
  grid-area: empty;
}
.header {
  display: flex;
  justify-content: space-between;
}
.header span {
  font-family: var(--font-semibold);
  font-size: var(--font-size-m);
  font-weight: var(--font-weight-m);
  white-space: nowrap;
}
.create-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  max-width: 320px;
  gap: 24px;
}
.create-wrapper header {
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
}
.date-mt {
  width: 308px;
}
.create-close {
  position: absolute !important;
  top: 16px;
  right: 16px;
}
.table td:nth-child(3) {
  width: 2%;
}
.table td:nth-child(1) {
  width: 40%;
}
.table th:nth-child(1) {
  width: 40%;
}
