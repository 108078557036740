.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.code {
  width: 250px;
  height: 250px;
  background-color: var(--accent);
  color: var(--sub-text-color);
  text-align: center;
  line-height: 250px;
  border-radius: 50%;
  font-size: 75px;
  margin-top: 40px;
}
.wrapper h2 {
  margin-bottom: 40px;
}