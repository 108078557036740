.default-reports {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}
.default-reports span {
  color: var(--dark-grey);
  text-align: center;
}
.error {
  color: var(--accent);
}
.error path {
  stroke: var(--accent);
}
.bold-text {
  color: var(--light-black);
}
