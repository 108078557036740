.table {
  padding: 0 40px;
}
.table thead {
  display: none;
}
.table td:nth-child(1) {
  padding: 0;
}
.table td:nth-child(1) button {
  width: 36px;
}
.table td:nth-child(1),
.table td:nth-child(3) {
  width: 1%;
}
.table td:nth-child(3) {
  padding-right: 8px !important;
}
.model-loader {
  height: 40px;
}

.form-wrapper {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 42px 40px;
  background-color: var(--light-grey);
  border-radius: 12px;
}
.form-row {
  display: flex;
  gap: 40px;
}
.form-field {
  width: 240px;
}
.form-field label {
  height: 22px;
  margin-bottom: 14px;
}
.loader {
  margin: -10px 0 0 7px;
}
