.wrapper {
  flex: 1;
  display: flex;
}
.list {
  display: flex;
  flex-direction: column;
  width: 240px;
  border-right: 1px solid var(--grey);
  overflow-y: auto;
}
.list-item__active,
.list-item {
  display: flex;
  align-items: center;
  padding: 12px 40px;
  cursor: pointer;
}
.list-item__active,
.list-item:hover {
  background-color: var(--dark-grey);
  color: var(--white) !important;
  transition: all 200ms ease;
}
.body {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
