.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 42px;
  max-height: 92px;
  min-height: 92px;
}
.header header {
  display: flex;
  gap: 16px;
}
.header h3 {
  font-family: var(--font-semibold);
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
  margin: 0;
  line-height: 40px;
}
.back {
  border-radius: 50% !important;
  padding: 0 7px !important;
  transform: rotate(90deg);
}

.info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.info header {
  font-family: var(--font-semibold);
  font-size: var(--font-size-xxl);
  font-weight: var(--font-weight-m);
  line-height: 58px;
  white-space: nowrap;
}
.data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60px;
  color: var(--grey);
}
.data b {
  font-family: var(--font-regular);
  color: var(--light-black);
  white-space: nowrap;
  font-weight: var(--font-weight-s);
}

.current-grid {
  flex: 1;
  display: grid;
  grid-template-areas:
    'dpfstatus dpfstatus'
    'info map'
    'main map'
    'extra temperature'
    'extra pedals'
    'extra light '
    'dpf light'
    'dpf doors'
    'dpf empty';
  grid-template-rows: 60px 128px 373px 114px 114px 18px;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 24px;
  padding: 24px 40px;
}
.history-grid {
  flex: 1;
  display: grid;
  grid-template-areas:
    'info map'
    'episodes map';
  grid-template-rows: 128px 1fr;
  grid-template-columns: 560px 1fr;
  column-gap: 40px;
  row-gap: 24px;
  padding: 24px 40px;
}
.maintenance-grid {
  flex: 1;
  display: grid;
  grid-template-areas:
    'info empty'
    'list list';
  grid-template-rows: 128px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  padding: 25px 40px;
}
