.body-header {
  line-height: 40px;
  margin: 12px 0;
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-m);
}
.body-row {
  display: flex;
  gap: 6px;
  margin-bottom: 6px;
}