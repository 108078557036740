.wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.logo {
  margin-top: 20px;
  margin-bottom: 68px;
}
.wrapper header {
  text-align: center;
  font-size: 32px;
  line-height: 40px;
  font-family: var(--font-regular);
  font-weight: var(--font-weight-s);
}
.wrapper span {
  line-height: 60px;
}
.wrapper button {
  width: 320px;
  height: 60px;
  margin: auto 0;
  border-radius: var(--radius-m);
  font-weight: var(--font-weight-s);
  font-family: var(--font-regular);
  font-size: var(--font-size-m);
}
