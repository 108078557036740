.report-wrapper {
  padding: 20px 40px;
}
.label-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  top: 8px;
  right: 0;
}
.circle-with-label {
  display: flex;
  align-items: center;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;
}
.circle-with-label:nth-of-type(2) .circle {
  background-color: var(--accent);
}
.circle-with-label:nth-of-type(1) .circle {
  background-color: var(--green);
}
.filters > div {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 44px;
}
.filters > div > div:nth-child(1) {
  flex: 1;
}
.chart-wrapper {
  width: 920px;
  height: 414px;
  background-color: var(--light-grey);
  border-radius: var(--radius-s);
  padding-top: 20px;
  padding-right: 20px;
}
.plate {
  margin-bottom: 20px;
}
