.table {
  padding: 0 40px;
}
.filters-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 40px 20px 40px;
  max-height: 154px;
}
.filters-wrapper header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  font-size: var(--font-size-l);
  font-weight: var(--font-weight-m);
}

.filters {
  display: grid;
  grid-template-areas:
    'model vin plate-number'
    'clear empty apply';
  grid-template-rows: repeat(2, 40px);
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 14px;
}
.filters-model {
  grid-area: model;
}
.filters-vin {
  grid-area: vin;
}
.filters-plate-number {
  grid-area: plate-number;
}
.filters-clear {
  grid-area: clear;
  padding: 0;
  justify-content: flex-start;
}
.filters-apply {
  grid-area: apply;
}
