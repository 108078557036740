.wrapper {
  display: flex;
  height: 100%;
}
.create {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 440px;
  padding: 10px 40px;
}
.check {
  flex: 1;
  min-width: 480px;
  padding: 10px 40px;
}
.wrapper header {
  font-family: var(--font-bold);
  font-weight: var(--font-weight-m);
  font-size: var(--font-size-l);
  line-height: 60px;
}
.field {
  margin-top: 10px;
}
.submit {
  margin-top: 10px;
  width: 100%;
}
.check-status {
  display: flex;
  margin-top: 26px;
  color: var(--dark-grey);
}
.check-status span {
  min-width: 160px;
  margin-right: 30px;
  line-height: 20px;
}

.create-fail {
  display: flex;
  flex-direction: column;
  color: var(--dark-grey);
  margin-bottom: 16px;
  color: var(--accent);
}
.create-fail span {
  margin-top: 8px;
}
.create-fail a {
  text-decoration: underline;
}
.create-fail a:hover {
  text-decoration: none;
}
