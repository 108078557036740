.plates-wrapper {
  width: fit-content;
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.default-reports {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.default-reports span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.report-wrapper {
  padding: 20px 40px;
}
.chart-wrapper {
  width: 900px;
  height: 353px;
  background-color: var(--light-grey);
  border-radius: var(--radius-s);
  padding-top: 20px;
  padding-right: 20px;
}
.filters > div {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 44px;
}
.filters > div > div:nth-child(1) {
  flex: 1;
}
